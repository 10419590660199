import * as React from 'react';
import {AppComponent, appComponent} from '../../client/app.component'; // TYPE ONLY!

/**
 * create original context with a blank object, and let appComponent initialize it.
 */
export let ServiceContext = React.createContext<any>({});

// Allow appComponent to re-init when it is ready
export const initContext = (appComponentParam: AppComponent) => {
    ServiceContext = React.createContext<any>(appComponentParam);
};

export const useService = (service: keyof typeof appComponent) => {
    return React.useContext(ServiceContext)[service];
};
